<template>
  <section v-if="editing">
    <section class="question">
      <div class="question-wrapper">
        <Tiptap @onChangeText="onChangeText" />
      </div>
    </section>
    <div class="add-choices" placeholder="Enter choice">
      <div v-for="(option, index) in editing.question.answers" :key="index">
        <Option :option="option" :index="index" />
      </div>
      <QuickAdd :quickAdd="quickAdd" @open-quick-add="openQuickAdd" />
      <div class="add-more-answer">
        <el-button type="info" plain v-if="!quickAdd" @click="addAnswer">
          Thêm đáp án <i class="el-icon-plus"></i>
        </el-button>
        <el-button type="primary" v-if="!quickAdd" @click="openQuickAdd" plain>
         Thêm nhanh <i class="el-icon-magic-stick"></i>
        </el-button>
      </div>
    </div>
    <AdvanceSetting />
    <div class="action-group">
      <el-button
        v-if="workspace.validate"
        type="primary"
        size="small"
        @click="save"
      >
      Hoành thành <i class="el-icon-circle-check"></i>
      </el-button>
      <el-button v-else type="primary" size="small" disabled>
        Hoành thành <i class="el-icon-circle-check"></i>
      </el-button>
      <el-button type="info" size="small" @click="cancel">
        Huỷ <i class="el-icon-close"></i>
      </el-button>
    </div>
  </section>
  <el-button
    v-else
    type="primary"
    @click="openFullScreen1"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
  </el-button>
</template>

<script>
import { mapState } from "vuex";
import QuickAdd from "@/components/builder/QuickAdd";
import AdvanceSetting from "@/components/builder/AdvanceSetting";
import Option from "@/components/builder/create/Option";
import Tiptap from "../Tiptap.vue";

export default {
  components: { QuickAdd, AdvanceSetting, Option, Tiptap },
  data() {
    return {
      validated: false,
      quickAdd: false,
      name: null,
    };
  },
  computed: {
    ...mapState("builder", ["editing", "workspace"]),
  },
  methods: {
    addAnswer() {
      this.$store.dispatch("builder/addAnswer");
    },
    openQuickAdd() {
      this.quickAdd = !this.quickAdd;
    },
    save() {
      this.$store.dispatch("builder/addQuestion", this.editing.question);
    },
    cancel() {
      this.$store.commit("builder/RESET_BUILDER");
    },
    onChangeText(text) {
      this.name = text;
    },
  },
  watch: {
    name: function () {
      if (this.name)
        return this.$store.commit(
          "builder/UPDATE_EDITING_QUESTION_NAME",
          this.name
        );

      return this.$store.commit("builder/UPDATE_VALIDATE", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button + .el-button {
  margin-left: 0;
}

.add-more-answer {
  margin: 15px 0 15px 0;
  display: grid;
  grid-template-columns: 10fr 2fr;
  grid-gap: 10px;
}

.question {
  &-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 10px;
  }

  &-name {
    width: 100%;
    border-top: none;
    border-bottom: 1px dashed;
    border-left: none;
    border-right: none;
    outline-width: 0;
    height: 46px;
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.action-group {
  margin-top: 20vh;
  display: flex;
  align-items: center;
  margin-right: 10px;
  .el-button + .el-button {
    margin-left: 10px;
  }
}

.warning::placeholder {
  color: #f56c6c;
}

.warning {
  caret-color: #f56c6c;
  border-bottom-color: #f56c6c;
}
</style>