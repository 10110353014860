<template>
  <div class="answer">
    <input
      v-model="name"
      class="option"
      :class="{
        warningOption:
          (option.hasOwnProperty('validate') && !option.validate) || !name,
      }"
    />
    <div class="answer-actions">
      <el-button
        v-if="option.correct"
        icon="el-icon-check"
        type="primary"
        @click="markCorrect(index)"
      >
      </el-button>
      <el-button
        v-else
        icon="el-icon-check"
        @click="markCorrect(index)"
      ></el-button>
      <el-button icon="el-icon-delete" @click="removeOption(index)"></el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["option", "index"],
  data() {
    return {
      name: this.option.name,
    };
  },
  methods: {
    removeOption(index) {
      this.$store.dispatch("builder/removeAnswer", index);
    },
    markCorrect(index) {
      this.$store.commit("builder/MARK_CORRECT", index);
    },
  },
  watch: {
    name: function () {
      this.$store.dispatch("builder/updateAnswer", {
        index: this.index,
        name: this.name,
      });
    },
    option: function () {
      this.name = this.option.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.answer {
  margin: 15px 0 15px 0;
  display: grid;
  grid-template-columns: 10fr 2fr;
  grid-gap: 10px;

  &-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
}

.option {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.warningOption {
  border-color: #f56c6c;
}
</style>