export default {
  data() {
    return {
      headers: {
        Authorization: this.$cookies.get('token'),
        Accept: 'application/json, text/plain, */*'
      },
      editorOption: {
        placeholder: "Add some note here...",
      },
      id: null,
      fileList: [],
      categoryId: null,
      title: "",
      metaTitle: "",
      metaDescription: "",
      content: "",
      status: "normal",
      cover: null,
      statusOptions: [
        {
          value: "normal",
          label: "Normal",
        },
        {
          value: "trending",
          label: "Trending",
        },
        {
          value: "ultra",
          label: "Ultra",
        },
        {
          value: "editor_choice",
          label: "Editor choice",
        },
      ],
    }
  },
}
