<template>
  <section>
    <div v-if="exam.name">
      <section class="header">
        <div class="header-left">
          <router-link to="/">
            <img src="@/assets/lg.png" class="logo" />
          </router-link>
        </div>
        <div class="header-right">
          <ExamName :name="exam.name" />
          <StepBar />
          <Actions />
        </div>
      </section>
      <section class="main">
        <Menu />
        <div
          class="workspace"
          v-loading="workspace.loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div class="workspace-left">
            <div v-for="page in exam.pages" :key="page.id">
              <PageItem :page="page" />
            </div>
            <AddPageButton />
            <Balloon />
          </div>
          <div class="workspace-right">
            <Selector v-if="workspace.opening === 'SELECTOR'" />
            <div class="builder" v-else>
              <AddPage v-if="workspace.opening === 'ADD_PAGE'" />
              <EditPage v-if="workspace.opening === 'EDIT_PAGE'" />

              <TextQuestion v-if="workspace.opening === 'TEXT'" />
              <EditTextQuestion v-if="workspace.opening === 'EDIT_TEXT'" />

              <Select v-if="workspace.opening === 'DROP_DOWN'" />
              <EditSelectQuestion
                v-if="workspace.opening === 'EDIT_DROP_DOWN'"
              />

              <Single v-if="workspace.opening === 'SINGLE_ANSWER'" />
              <EditSingleQuestion
                v-if="workspace.opening === 'EDIT_SINGLE_ANSWER'"
              />

              <!-- <Multiple v-if="workspace.opening === 'MULTIPLE_CHOISE'"/>
                        <EditMultiple v-if="workspace.opening === 'EDIT_MULTIPLE_CHOISE'"/> -->
            </div>
          </div>
        </div>
      </section>
      <PreviewBox
        v-if="workspace.openPreview"
        :visible="workspace.openPreview"
      />
    </div>
    <div
      v-else
      v-loading="true"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Menu from "@/components/commons/Menu.vue";
import PageItem from "@/components/builder/create/PageItem.vue";
import Balloon from "@/components/builder/create/Balloon.vue";
import AddPageButton from "@/components/builder/create/AddPageButton.vue";
import StepBar from "@/components/builder/StepBar.vue";
import Actions from "@/components/builder/create/Actions.vue";
import ExamName from "@/components/builder/ExamName.vue";
import AddPage from "@/components/builder/create/AddPage.vue";
import EditPage from "@/components/builder/edit/EditPage.vue";
import TextQuestion from "@/components/builder/create/TextQuestion.vue";
import Selector from "@/components/builder/create/Selector.vue";
import Select from "@/components/builder/create/Select.vue";
import EditSelectQuestion from "@/components/builder/edit/EditSelectQuestion.vue";
import Single from "@/components/builder/create/Single.vue";
import EditTextQuestion from "@/components/builder/edit/EditTextQuestion.vue";
import EditSingleQuestion from "@/components/builder/edit/EditSingleQuestion.vue";
import PreviewBox from "../components/builder/PreviewBox";
/* import Multiple from '@/components/builder/create/Multiple.vue'
import EditMultiple from '@/components/builder/edit/EditMultiple.vue' */

export default {
  components: {
    Selector,
    PageItem,
    Balloon,
    AddPageButton,
    Menu,
    StepBar,
    Actions,
    ExamName,
    AddPage,
    TextQuestion,
    Select,
    EditSelectQuestion,
    EditTextQuestion,
    Single,
    EditPage,
    EditSingleQuestion,
    PreviewBox,
    /* Multiple,
        EditMultiple */
  },
  mounted() {
    this.$store.dispatch("builder/getExam", this.$route.params.id);
  },
  computed: {
    ...mapState("builder", ["exam", "workspace"]),
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 50px;
  height: auto;
}
.header {
  display: grid;
  grid-template-columns: 1fr 20fr;
  max-height: 70px;
  justify-content: center;
  align-content: center;
  box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
  background-color: #fff;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  &-left {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-right {
    display: grid;
    grid-template-columns: 3fr 6fr 3fr;
    justify-content: center;
    align-content: center;
  }
}

.builder {
  min-height: 60vh;
  box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
  background-color: #fff;
  margin: 0 0 0 2em;
  padding: 0 20px 2em 20px;
}

.main {
  min-height: 95vh;
  display: grid;
  grid-template-columns: 1fr 20fr;
}

.workspace {
  padding-top: 2em;
  border-top-left-radius: 3px;
  display: grid;
  grid-template-columns: 3fr 9fr;

  &-left {
    height: 100%;
    transition: all 0.3s;
    border-right: thin solid rgba(77, 52, 70, 0.2);
    background-color: #fff;
  }
}
</style>