<template>
  <div class="page" v-if="page">
    <div class="page-header">
      <div>
        <p class="pane-name" @click="openEditor" v-if="!openEdit">
          {{ page.name }}
        </p>
        <el-input
          v-show="openEdit"
          v-model="name"
          @clear="name = null"
          @change="onChange"
          @blur="onBlur"
          :ref="`page${page.id}`"
          size="small"
          clearable
        >
        </el-input>
      </div>
      <div
        class="collapsible"
        v-bind:class="{ active: isActive }"
        @click="pageCollaspe"
      ></div>
    </div>
    <div v-show="isActive">
      <draggable
        ghost-class="ghost"
        @end="endOrder(page.id)"
        v-model="list"
        handle=".dragger"
      >
        <div v-for="question in page.questions" :key="question.id">
          <QuestionItem :question="question" />
        </div>
      </draggable>
      <div class="page-action">
        <div class="action-group">
          <div class="action-item" @click="editPage(page)">
            <el-tooltip effect="dark" content="Sửa" placement="bottom">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </div>
          <el-popconfirm
            class="action-item"
            title="Bạn có chắc muốn xoá trang này?"
            @confirm="removePage(page.id)"
            confirmButtonText="Xoá"
            cancelButtonText="Huỷ"
          >
            <el-tooltip
              class="action-item"
              slot="reference"
              effect="dark"
              content="Xoá"
              placement="bottom"
              @click="removePage"
            >
              <i class="el-icon-delete"></i>
            </el-tooltip>
          </el-popconfirm>
          <el-tooltip
            class="action-item"
            effect="dark"
            content="Copy thành trang mới"
            placement="bottom"
          >
            <i class="el-icon-copy-document"></i>
          </el-tooltip>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          content="Thêm câu hỏi"
          placement="bottom"
        >
          <div class="add-question-btn" @click="openSelector(page.id)">
            <i class="el-icon-plus"></i>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
  <el-button
    v-else
    type="primary"
    @click="openFullScreen1"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
  </el-button>
</template>

<script>
import QuestionItem from "@/components/builder/QuestionItem.vue";
import draggable from "vuedraggable";

export default {
  components: { QuestionItem, draggable },
  props: ["page"],
  data() {
    return {
      isActive: true,
      openEdit: false,
      name: this.page.name,
      rules: {
        name: [
          {
            required: true,
            message: "Nhập tên bài tập",
            trigger: "change",
          },
        ],
      },
      list: this.page.questions,
    };
  },
  methods: {
    pageCollaspe() {
      this.isActive = !this.isActive;
    },
    openEditor() {
      this.openEdit = true;
      this.$nextTick(function () {
        this.$refs[`page${this.page.id}`].focus();
      });
    },
    onBlur() {
      (this.openEdit = false), (this.name = this.page.name);
    },
    onChange() {
      if (this.name !== this.page.name && this.name !== "") {
        this.$store.dispatch("builder/updatePageName", {
          id: this.page.id,
          name: this.name,
        });
      }
    },
    unFocus() {
      this.$nextTick(function () {
        this.$refs[`page${this.page.id}`].blur();
      });
    },
    removePage(id) {
      this.$store.dispatch("builder/deletePage", id);
    },
    openSelector(pageId) {
      this.$store.commit("builder/QUESTION_SELECTED", {
        pageId,
        type: "SELECTOR",
      });
    },
    editPage(page) {
      this.$store.commit("builder/START_EDIT_PAGE", page);
    },
    endOrder(pageId) {
      this.$store.dispatch("builder/orderQuestion", {
        list: this.list,
        id: pageId,
      });
    },
  },
  watch: {
    page: {
      handler: function ({ name, questions }) {
        this.name = name;
        this.list = questions;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.el-button--mini {
  background-color: #67c23a !important;
  border-color: #67c23a;
}

.el-popconfirm__action p {
  color: #62686f;
}
.add-question {
  &-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &-text {
    font-size: 12px;
    color: #62686f;
    font-weight: 600;
    margin-left: 10px;
  }
}
.page {
  margin: 0 0.5em 1em 0;

  &-action {
    display: flex;
    justify-content: space-between;
    min-height: 42px;
    margin: 10px 0 0 20px;
  }

  &-header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
    cursor: pointer;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.action-group {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  min-height: 42px;
  margin: 1em 0.5em 1em 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  flex-grow: 5;
}

.action-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.action-item:hover {
  background: #f4f4f5;
}

.add-question-btn {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  min-height: 42px;
  margin: 1em 0 1em 0;
  width: 20%;
  background-color: #67c23a;
  color: white;
  display: flex;
  justify-content: center;
}

.collapsible {
  cursor: pointer;
  padding: 18px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.3s;
}

.active,
.collapsible:hover {
  background-color: #fff;
}

.collapsible:hover {
  background: #f4f4f5;
}

.collapsible:after {
  content: "\002B";
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.page-form-item {
  margin: 0;
}

.pane-name {
  cursor: text;
}
</style>