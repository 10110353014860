<template>
  <section class="add-page">
    <div class="add-page-wrapper">
      <input
        type="text"
        class="add-page-name"
        v-bind:class="{ warning: !validated }"
        placeholder="Nhập tên trang..."
        v-model="name"
      />
    </div>
    <div class="editor-wrapper">
      <quill-editor v-model="note" :options="editorOption" />
    </div>

    <div class="form-line">
      <p class="form-line-label">File câu hỏi</p>
      <el-upload
        ref="uploadCover"
        :multiple="false"
        :file-list="questionFileList"
        :action="`${domain}/api/v1/storage/image/upload`"
        class="upload-demo"
        :on-success="afterUploadQuestionSuccess"
        :headers="headers"
        @clearFiles="clearQuestionFileList"
      >
        <el-button size="small" type="default">Click to upload</el-button>
      </el-upload>
    </div>

    <div class="form-line">
      <p class="form-line-label">File đáp án</p>
      <el-upload
        ref="uploadCover"
        :multiple="false"
        :file-list="answerFileList"
        :action="`${domain}/api/v1/storage/image/upload`"
        class="upload-demo"
        :on-success="afterUploadAnswerSuccess"
        :headers="headers"
        @clearFiles="clearAnswerFileList"
      >
        <el-button size="small" type="default">Click to upload</el-button>
      </el-upload>
    </div>

    <el-button
      type="info"
      size="small"
      @click="$store.commit('builder/RESET_BUILDER')"
    >
      Huỷ <i class="el-icon-close"></i>
    </el-button>

    <el-button v-if="validated" type="primary" size="small" @click="save">
      Hoành thành <i class="el-icon-circle-check"></i>
    </el-button>

    <el-button v-else type="primary" size="small" disabled>
      Hoàn thành <i class="el-icon-circle-check"></i>
    </el-button>
  </section>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

const domain = process.env.VUE_APP_API_URL

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      name: null,
      note: null,
      validated: false,
      questionFile: null,
      answerFile: null,
      domain: domain,
      headers: { 'Authorization': this.$cookies.get('token') },
      questionFileList: [],
      answerFileList: [],
      editorOption: {
        placeholder: "Nhập một số lưu ý cho người làm bài nếu có...",
      },
    };
  },
  methods: {
    afterUploadQuestionSuccess({url}) {
      this.questionFile = url
      this.fileList = [{name: url.split('/').pop(), url}]
    },
    afterUploadAnswerSuccess({url}) {
      this.answerFile = url
      this.fileList = [{name: url.split('/').pop(), url}]
    },
    clearQuestionFileList() {
      this.questionFileList = []
    },
    clearAnswerFileList() {
      this.answerFileList = []
    },
    save() {
      this.$store.dispatch("builder/adPage", {
        name: this.name,
        note: this.note,
        examId: this.$route.params.id,
        answerFile: this.answerFile,
        questionFile: this.questionFile
      });

      this.name = null
      this.note = null
      this.answerFile = null
      this.questionFile = null
    },
  },
  watch: {
    name() {
      if (this.name) {
        return (this.validated = true);
      }

      return (this.validated = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-line{
  margin-bottom: 20px;

  &-label{
    margin-bottom: 10px;
  }
}
.add-page {
  padding-bottom: 2em;

  &-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }

  &-name {
    width: 100%;
    border-top: none;
    border-bottom: 1px dashed;
    border-left: none;
    border-right: none;
    outline-width: 0;
    height: 46px;
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.warning::placeholder {
  color: #f56c6c;
}

.editor-wrapper {
  margin-bottom: 20px;
}

.warning {
  caret-color: #f56c6c;
  border-bottom-color: #f56c6c;
}

.ql-editor {
  min-height: 300px !important;
}

.ql-editor strong {
  font-weight: bold;
}

.ql-editor em {
  font-style: italic;
}
</style>