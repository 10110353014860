<template>
  <div class="tiptap_editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <icon name="bold" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <icon name="italic" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <icon name="strike" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <icon name="underline" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <icon name="paragraph" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <icon name="ul" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <icon name="ol" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <icon name="code" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.image() }"
          @click="showImagePrompt(commands.image)"
        >
          <icon name="image" />
        </button>
      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
    <UploadBox
      :open="openUploadImage"
      @uploadSuccess="uploadSuccess"
      @close="openUploadImage = false"
    />
  </div>
</template>

<script>
import Icon from "./Icon";
import UploadBox from "@/components/commons/UploadBox.vue";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";
export default {
  props: {
    name: {
      default: "",
    },
  },
  components: {
    EditorContent,
    EditorMenuBar,
    Icon,
    UploadBox,
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
        ],
        content: this.name ? `${this.name}` : "",
        onUpdate: ({ getHTML }) => {
          this.$emit("onChangeText", getHTML());
        },
      }),
      openUploadImage: false,
      command: null,
    };
  },
  mounted() {
    this.editor.setContent(this.name || "");
  },
  methods: {
    showImagePrompt(command) {
      this.command = command;
      this.openUploadImage = true;
    },
    uploadSuccess(image) {
      this.openUploadImage = false;
      if (image.url !== null) this.command({ src: image.url });
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  watch: {
    name: {
      handler(v) {
        this.name = v;
      },
      immediate: true
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/tiptap/sass/main.scss";
.tiptap_editor{
  width: 100%;
  border-bottom: dashed 1px;
  padding-bottom: 5px;
}

img {
  max-width: 50%;
}
</style>
