<template>
  <div class="conatiner">
    <div class="left"></div>
    <div class="right" :loading="loading">
      <div class="not-member">
        Not a member?
        <b class="signup">
          <router-link to="/sign-up" class="signup"> Sign up now </router-link>
        </b>
      </div>
      <h3 class="welcome">Welcome to baitaptracnghiem.com</h3>
      <div class="form-item">
        <p class="label">Email</p>
        <el-input v-model="email" clearable> </el-input>
        <p
          class="error"
          v-for="(item, index) in validate.email"
          :key="`email-${index}`"
        >
          {{ item }}
        </p>
      </div>
      <div class="form-item">
        <p class="label">Password</p>
        <el-input v-model="password" show-password clearable> </el-input>
        <p
          class="error"
          v-for="(item, index) in validate.password"
          :key="`password-${index}`"
        >
          {{ item }}
        </p>
      </div>
      <p class="forgot"></p>
      <el-button type="primary" class="custom-btn" @click="login"
        ><b>Sign In</b></el-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["validate", "loading"]),
  },
  data() {
    return {
      email: null,
      password: null,
    };
  },

  mounted() {
    this.$store.commit("auth/UPDATE_VALIDATE_ERRORS", []);
  },

  methods: {
    login() {
      this.$store.dispatch("auth/login", {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style scoped>
.conatiner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
}
.welcome {
  font-size: 20px;
  font-weight: 500;
}
.left {
  background-image: url("../assets/illustration_04.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.forgot {
  color: rgb(100 213 29);
  color: #5e6d82;
  font-size: 13px;
  font-weight: 500;
  margin: 0px;
  padding: 14px 0px;
  cursor: pointer;
}
.right {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 15% 10% 20% 10%;
}
.label {
  font-size: 13px;
  padding: 9px 0px;
  color: rgb(0 0 0);
}
.custom-btn {
  margin-left: unset !important;
  margin-bottom: 10px;
}
.signup {
  color: #5e6d82;
  text-decoration: none;
  cursor: pointer;
}
.not-member {
  position: fixed;
  top: 10px;
  font-size: 15px;
  right: 10px;
}
</style>