import { create, getPosts, deletePost, getPost, edit, search } from '@/api/post.js'
import { message } from '@/utils/message.js'

const state = {
  post: {},
  loading: false,
  validate: [],
  posts: [],
  pagination: {
    page: 1,
    lastPage: 1,
    total: 0,
    perPage: 10
  },
  sort: {
    type: 'default',
    name: 'Tên'
  },
  keyword: null,
  searchResults: []
}

// getters
const getters = {}

// actions

const actions = {
  async create({commit}, data) {
    commit('UPDATE_LOADING', true)
    try {
      await create(data)
    } catch ({response}) {
      message(response.data.message, 'error')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
  async getPosts({commit}, {categoryId}) {
    commit('UPDATE_LOADING', true)
    try {
      const { sort, keyword, pagination } = state
      const { data } = await getPosts(categoryId, pagination.page, sort.type, keyword)
      commit('UPDATE_POSTS', data)
    } catch ({response}) {
      message(response.data.message, 'error')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
  async delete({commit}, {categoryId, postId}) {
    commit('UPDATE_LOADING', true)
    try {
      const { data } = await deletePost(categoryId, postId)
      commit('UPDATE_POSTS', data)
    } catch ({response}) {
      message(response.data.message, 'error')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
  async getPost({commit}, postId) {
    commit('UPDATE_LOADING', true)
    try {
      const { data } = await getPost(postId)
      commit('UPDATE_POST', data)
    } catch ({response}) {
      message(response.data.message, 'error')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
  async editPost({commit}, reqData) {
    commit('UPDATE_LOADING', true)
    try {
      const { data } = await edit(reqData)
      commit('UPDATE_POST', data)
    } catch ({response}) {
      message(response.data.message, 'error')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },

  async search({commit}, keyword) {
    try {
      const { data } = await search({keyword})
      commit('UPDATE_SEARCH_RESULTS', data)
    } catch ({response}) {
      message(response.data.message, 'error')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  }
}

// mutations

const mutations = {
  UPDATE_POST(state, post){
    state.post = post
  },
  UPDATE_LOADING(state, status){
    state.loading = status
  },
  UPDATE_POSTS(state, response) {
    state.posts = response.data
    state.pagination.page = response.current_page
    state.pagination.total = response.total
    state.pagination.lastPage = response.last_page
  },
  RESET_PAGINATE() {
    state.pagination = {
      page: 1,
      lastPage: 1,
      total: 0,
      perPage: 10
    }
  },
  UPDATE_SORT_TYPE(state, data) {
    state.sort = data
  },
  UPDATE_SEARCH_KEYWORD(state, keyword) {
    state.keyword = keyword
  },
  UPDATE_CURRENT_PAGE(state, page) {
    state.pagination.page = page
  },
  UPDATE_SEARCH_RESULTS(state, {posts}) {
    state.searchResults = posts
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}