<template>
  <section class="page-builder">
    <div class="page-builder-wrapper">
      <input
        type="text"
        class="page-builder-name"
        v-bind:class="{ warning: !validated }"
        placeholder="Nhập tên trang..."
        v-model="name"
      />
    </div>
    <div class="editor-wrapper">
      <quill-editor
        v-model="note"
        :options="editorOption"
        :key="editingPage.id"
      />
    </div>

    <div class="form-line">
      <p class="form-line-label">File câu hỏi {{ this.editingPage.questions_file }}</p>
      <el-upload
        ref="uploadCover"
        :multiple="false"
        :file-list="questionFileList"
        :action="`${domain}/api/v1/storage/image/upload`"
        class="upload-demo"
        :on-success="afterUploadQuestionSuccess"
        :headers="headers"
        @clearFiles="clearQuestionFileList"
      >
        <el-button size="small" type="default">Click to upload</el-button>
      </el-upload>
    </div>

    <div class="form-line">
      <p class="form-line-label">File đáp án {{ this.editingPage.answers_file }}</p>
      <el-upload
        ref="uploadCover"
        :multiple="false"
        :file-list="answerFileList"
        :action="`${domain}/api/v1/storage/image/upload`"
        class="upload-demo"
        :on-success="afterUploadAnswerSuccess"
        :headers="headers"
        @clearFiles="clearAnswerFileList"
      >
        <el-button size="small" type="default">Click to upload</el-button>
      </el-upload>
    </div>

    <div class="gen-questions">
      <span>Nhập số câu hỏi</span>
      <el-input
        type="textarea"
        :rows="1"
        placeholder="Nhập số câu hỏi"
        v-model="totalQuestions"
        class="gen-questions-input"
      >
      </el-input>

      <span>Nhập số đáp án mỗi hỏi</span>
      <el-input
        type="textarea"
        :rows="1"
        placeholder="Nhập số đáp án"
        v-model="answersPerQuestion"
        class="gen-questions-input"
      >
      </el-input>

      <el-button
        v-if="totalQuestions && answersPerQuestion"
        type="warning"
        size="small"
        class="gen-questions-btn"
        plain
        @click="generatePageQuestions"
      >
        Tạo đề
      </el-button>
    </div>

    <el-button v-if="validated" type="primary" size="small" @click="save">
      Save <i class="el-icon-circle-check"></i>
    </el-button>

    <el-button v-else type="primary" size="small" disabled>
      Save <i class="el-icon-circle-check"></i>
    </el-button>

    <el-button
      type="info"
      size="small"
      @click="$store.commit('builder/RESET_BUILDER')"
    >
      Cancel <i class="el-icon-close"></i>
    </el-button>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
const domain = process.env.VUE_APP_API_URL;

export default {
  components: {
    quillEditor,
  },
  computed: {
    ...mapState("builder", ["editing", "workspace"]),
    ...mapGetters("builder", ["editingPage"]),
  },

  mounted() {
    const { name, note } = this.editingPage;
    this.name = name;
    this.note = note;
  },
  data() {
    return {
      totalQuestions: null,
      answersPerQuestion: null,
      name: null,
      note: null,
      validated: false,
      editorOption: {
        placeholder: "Add some note here...",
      },
      questionFile: null,
      answerFile: null,
      domain: domain,
      headers: { Authorization: this.$cookies.get("token") },
      questionFileList: [],
      answerFileList: [],
    };
  },
  methods: {
    generatePageQuestions() {
        this.$store.dispatch("builder/generatePageQuestions", {
        pageId: this.editingPage.id,
        totalQuestions: this.totalQuestions,
        answersPerQuestion: this.answersPerQuestion
      });
    },
    afterUploadQuestionSuccess({ url }) {
      this.questionFile = url;
      this.fileList = [{ name: url.split("/").pop(), url }];
    },
    afterUploadAnswerSuccess({ url }) {
      this.answerFile = url;
      this.fileList = [{ name: url.split("/").pop(), url }];
    },
    clearQuestionFileList() {
      this.questionFileList = [];
    },
    clearAnswerFileList() {
      this.answerFileList = [];
    },
    save() {
      this.$store.dispatch("builder/editPage", {
        id: this.editingPage.id,
        data: {
          name: this.name,
          note: this.note,
          questions_file: this.answerFile,
          answers_file: this.questionFile,
        },
      });
    },
  },
  watch: {
    name: function () {
      if (this.name) {
        return (this.validated = true);
      }

      return (this.validated = false);
    },
    editingPage: function (page) {
      const { name, note, answers_file, questions_file } = page;
      this.name = name;
      this.note = note;
      (this.answerFile = answers_file), (this.questionFile = questions_file);
    },
  },
};
</script>

<style lang="scss">
.gen-questions {
  margin-bottom: 20px;
}
.gen-questions-input {
  margin-bottom: 15px;
  margin-top: 15px;
}
.page-builder {
  padding-bottom: 2em;
  &-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }

  &-name {
    width: 100%;
    border-top: none;
    border-bottom: 1px dashed;
    border-left: none;
    border-right: none;
    outline-width: 0;
    height: 46px;
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.form-line {
  margin-bottom: 20px;

  &-label {
    margin-bottom: 10px;
  }
}

.warning::placeholder {
  color: #f56c6c;
}

.editor-wrapper {
  margin-bottom: 20px;
}

.ql-editor {
  min-height: 300px !important;
}
.warning {
  caret-color: #f56c6c;
  border-bottom-color: #f56c6c;
}

.ql-editor strong {
  font-weight: bold;
}

.ql-editor em {
  font-style: italic;
}
</style>