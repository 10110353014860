export default {
    TEXT: {
        name: 'TEXT',
        note: 'Text question'
    },
    SINGLE_ANSWER: {
        name: 'SINGLE_ANSWER',
        note: 'Single answer'
    },
    /* TEXT_DISPLAY: {
        name: 'TEXT_DISPLAY',
        note: 'Text display'
    }, */
    DROP_DOWN: {
        name: 'DROP_DOWN',
        note: 'Dropdown'
    },
}