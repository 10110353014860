import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import builder from './modules/builder'
import exam from './modules/exam'
import post from './modules/post'
import category from './modules/category'
import auth from './modules/auth'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    builder,
    exam,
    post,
    auth,
    category
  },
  strict: debug,
  plugins: [createPersistedState({
    paths: ['auth']
  })],
})