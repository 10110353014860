<template>
  <div class="exam-name">
    <h6 class="name" @click="openEditor" v-show="!openEdit">{{ name }}</h6>
    <el-form
      :model="examName"
      :rules="rules"
      ref="examName"
      v-show="openEdit"
      class="name-form"
      @submit.prevent.native="unFocus"
    >
      <el-form-item prop="name" class="name-form-item">
        <el-input
          v-show="openEdit"
          v-model="examName.name"
          placeholder="Enter name"
          @clear="examName.name = null"
          @change="onChange"
          @blur="onBlur"
          ref="nameInput"
          clearable
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["name"],
  data() {
    return {
      openEdit: false,
      examName: {
        name: this.name,
      },
      rules: {
        name: [
          {
            required: true,
            message: "Nhập tên bài tập",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    openEditor() {
      this.openEdit = true;
      this.$nextTick(function () {
        this.$refs.nameInput.focus();
      });
    },
    onChange() {
      if (this.examName.name !== this.name && this.examName.name !== "") {
        this.$refs.examName.validate((valid) => {
          if (valid) {
            return this.$store.dispatch("builder/updateExamName", {
              id: this.$route.params.id,
              name: this.examName.name,
            });
          }
          return false;
        });
      }
    },
    onBlur() {
      (this.openEdit = false), (this.examName.name = this.name);
    },
    unFocus() {
      this.$nextTick(function () {
        this.$refs.nameInput.blur();
      });
    },
  },
  watch: {
    name() {
      this.examName.name = this.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.exam-name {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}
.name {
  font-size: 20px;

  &-form {
    width: 98%;
  }
  &-form-item {
    margin-bottom: 0;
  }
}
</style>