<template>
    <div class="class-box">
        <div class="first-col">
            <img src="@/assets/exam.png" class="class-icon"/>
        </div>
        <div class="class-info-item second-col">
            <p class="class-name">{{ exam.name }}</p>
            <p class="last-edit"><b>Cập nhật:</b> {{ exam.created_at | updatedAt }}</p>
        </div>
        <div class="class-info-item">
            <router-link :to="`/exam/${exam.id}/submission`" class="link">
                <i class="el-icon-circle-check ic"></i>
                <b class="highlight">{{ exam.submission_count }}</b> Lượt nộp bài
            </router-link>
        </div>
        <p class="class-info-item">
            <router-link :to="`/builder/${exam.id}`" class="link">
                <i class="el-icon-star-off ic"></i>
                <b class="highlight">{{ exam.questions_count }}</b> Câu hỏi
            </router-link>
        </p>
            <p class="class-info-item">
            <section>
                <i class="el-icon-message ic"></i>
                <b class="highlight">0</b> Thông báo
            </section>
        </p>
        <el-dropdown @command="command" class="custom-dropdown">
            <el-button type="primary">
                Thao tác<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                    <router-link :to="`/builder/${exam.id}`" class="custom-link-item">
                        <i class="el-icon-edit"></i> Sửa
                    </router-link>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
import moment from 'moment'

export default {
    props: ['exam'],
    methods: {
        command({type, data}) {
            if (type === 'share') this.$emit('shareLink', data)
            if (type === 'delete') this.$emit('openDeleteBox', data)
        }
    },
    filters: {
        updatedAt(time) {
            return time ? moment(time).fromNow() : 'Unknow'
        }
    }
}
</script>
<style lang="scss" scoped>
.last-edit {
    font-size: 15px;
    color: #63686F;
}
.first-col{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
.second-col{
    padding-left: 5vw;
}
.link{
    text-decoration: none;
    color: black;
}
.class-info-item{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
}
.class-name{
    font-weight: bold;
}
.link:hover{
    text-decoration: none;
    color: #44ce6f;
}
.custom-dropdown{
    height: 40px;
}
.class{
    &-icon{
        width: 100px;
        height: auto;
    }
    &-box{
        border: none;
        box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
        background-color: #fff;
        background-clip: border-box;
        border-radius: .25rem;
        padding: 10px 10px 10px 10px;
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr 3fr 2fr 2fr 2fr 2fr;
        margin-top: 15px;
        align-items: inherit;
        justify-items: center;
    }

    &-box:hover{
        box-shadow: 0px 2px 5px rgba(0,0,0,0.15);
        transition: 0.3s;
    }
}

.ic{
    font-size: 20px;
    margin-right: 10px;   
}
.highlight{
    font-weight: bold;
    color: #44ce6f;
}

.custom-link-item{
    text-decoration: none;
    color: #63686F;
}

</style>