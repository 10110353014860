<template>
  <div class="question">
    <div class="question-group">
      <div @click="editQuestion" class="item aaa">
        <span v-html="$options.filters.questionNameShorter(question.name)">
        </span>
      </div>
      <div class="question-actions">
        <el-popconfirm
          class="item"
          title="Bạn có chắc muốn xoá câu hỏi này?"
          @confirm="removeQuestion(question.id)"
          confirmButtonText="Tiếp tục"
          cancelButtonText="Huỷ"
        >
          <i slot="reference" class="el-icon-delete"></i>
        </el-popconfirm>

        <el-popconfirm
          class="item"
          title="Bạn chắc chắn muốn copy câu hỏi này?"
          @confirm="copyQuestion(question.id)"
          confirmButtonText="Tiếp tục"
          cancelButtonText="Huỷ"
        >
          <i slot="reference" class="el-icon-copy-document"></i>
        </el-popconfirm>
        <div class="dragger">
          <div class="drag-element">
            <i class="el-icon-rank item"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question"],
  data() {
    return {
      openEdit: false,
      questionName: {
        name: this.question.name,
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please input question name",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    openEditor() {
      this.openEdit = true;
      this.$nextTick(function () {
        this.$refs[`question${this.question.id}`].focus();
      });
    },
    onChange() {
      if (
        this.questionName.name !== this.question.name &&
        this.questionName.name !== ""
      ) {
        this.$refs[`questionForm${this.question.id}`].validate((valid) => {
          if (valid) {
            return this.$store.dispatch("builder/updateQuestionName", {
              id: this.question.id,
              name: this.questionName.name,
            });
          }
          return false;
        });
      }
    },
    onBlur() {
      this.openEdit = false;
      this.questionName.name = this.question.name;
    },

    removeQuestion(id) {
      this.$store.dispatch("builder/deleteQuestion", id);
    },

    editQuestion() {
      this.$store.commit("builder/START_EDIT_QUESTION", {
        type: `EDIT_${this.question.type}`,
        question: this.question,
      });
    },
    copyQuestion(id) {
      this.$store.dispatch("builder/copyQuestion", id);
    },
  },
  watch: {
    question: {
      handler: function () {
        this.questionName.name = this.question.name;
      },
      deep: true,
    },
  },
  filters: {
    questionNameShorter: function (name) {
      let plainText = name.replace(/<[^>]+>/g, "");
      return plainText.length > 20
        ? `${plainText.substring(0, 20).replace(/\s+$/, "")}...`
        : plainText;
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  min-height: 42px;
  margin: 10px 0 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

  &-group {
    width: 100%;
    display: grid;
    grid-template-columns: 9fr 3fr;
    justify-content: center;
    align-content: center;
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.item {
  cursor: pointer;
}
.drag-element {
  cursor: grab;
}

.question-form-item {
  margin: 0;
}
</style>