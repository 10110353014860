<template>
    <div>
        <section class="question">
            <div class="question-wrapper">
                <Tiptap 
                    @onChangeText="onChangeText"
                    :name="editingQuestion.name"
                    :key="editing.question.id"
                />
            </div>
            <el-input placeholder="Text question" :disabled="true"></el-input>
        </section>
        <AdvanceSetting />
        <div class="action-group">
            <el-button 
                v-if="workspace.validate" 
                type="primary" 
                size="small" 
                @click="save"
            >
            Hoành thành <i class="el-icon-circle-check"></i>
            </el-button>
            <el-button v-else type="primary" size="small" disabled>
                Hoành thành <i class="el-icon-circle-check"></i>
            </el-button>
            <el-button type="info" size="small" @click="$store.commit('builder/RESET_BUILDER')">
                Huỷ <i class="el-icon-close"></i>
            </el-button>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import AdvanceSetting from '@/components/builder/AdvanceSetting.vue'
import Tiptap from '../Tiptap.vue'

export default {
    components: { AdvanceSetting, Tiptap },
    data(){
        return {
            name: null,
            validated: false
        }
    },
    computed: {
        ...mapState('builder', ['editing', 'workspace']),
        ...mapGetters('builder', ['editingQuestion']),
    },
    methods:{
        save(){
           this.$store.dispatch('builder/editQuestion', {
               id: this.editingQuestion.id,
               name: this.name
           })
        },
        onChangeText(text){
            this.name = text
        }
    },
    watch: {
        name: function(v){ 
            if(v && v !== '<p></p>'){    /* '<p></p>' is default tiptap text */
                if(this.name) this.$store.commit('builder/UPDATE_EDITING_QUESTION_NAME', this.name)
                return this.$store.commit('builder/UPDATE_VALIDATE', true)
            }

            return this.$store.commit('builder/UPDATE_VALIDATE', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.question{
    &-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        padding-top: 10px;
    }

    &-name{
        width: 100%;
        border-top: none;
        border-bottom: 1px dashed;
        border-left: none;
        border-right: none;
        outline-width: 0;
        height: 46px;
        font-size: 20px;
        padding-bottom: 10px;
    }
}

.action-group{
    margin-top: 20vh;
}

.warning::placeholder{
    color: #f56c6c;;
}

.warning{
    caret-color: #f56c6c;
    border-bottom-color: #f56c6c;
}
</style>