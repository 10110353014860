import qs from 'qs'
import axios from "@/utils/axios"

export const create = data => axios.post(`/admin/post/create`, data)
export const getPosts = (categoryId, page, sort, keyword) => axios.get('/admin/exam/list/by-category', {
  params: { categoryId, page, sort, keyword }, 
  paramsSerializer: params => qs.stringify(params)
})
export const deletePost = (categoryId, postId) => axios.delete(`/admin/post/${postId}?categoryId=${categoryId}`)
export const getPost = id => axios.get(`/admin/post/${id}`)
export const edit = data => axios.patch(`/admin/post/${data.id}`, data)
export const search = params => axios.get(`/admin/post/search`, {params})