<template>
  <el-dialog
    title="Preview question."
    :visible="workspace.openPreview"
    @close="$store.commit('builder/RESET_PREVIEW')"
    center
    custom-class="preview-dialog"
  >
    <Single
      v-if="workspace.preview.type === 'SINGLE_ANSWER'"
      :question="workspace.preview"
    />
    <TextQuestion
      v-if="workspace.preview.type === 'TEXT'"
      :question="workspace.preview"
    />
    <Select
      v-if="workspace.preview.type === 'DROP_DOWN'"
      :question="workspace.preview"
    />
    <!-- <Multiple v-if="workspace.preview.type === 'MULTIPLE_CHOISE'" :question="workspace.preview"/> -->
  </el-dialog>
</template>
<script>
import { mapState } from "vuex";
import Single from "../questions/Single";
import TextQuestion from "../questions/TextQuestion";
import Select from "../questions/Select";
/* import Multiple from '@/components/questions/Multiple' */

export default {
  components: { Single, TextQuestion, Select /* Multiple */ },
  computed: {
    ...mapState("builder", ["workspace"]),
  },
};
</script>
<style lang="scss">
.preview-dialog {
  margin-left: 30% !important;
}
</style>