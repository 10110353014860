<template>
  <div class="class-box">
    <img src="@/assets/classroom.png" class="class-icon" />
    <div class="class-name" v-if="category.parent_id > 0">  
      <h3>{{ category.name }}</h3>&nbsp;({{ category.parent.name }})
    </div> 
    <h3 v-else class="class-name">{{ category.name }}</h3>
    <p class="class-info-item">
      <router-link :to="`/category/${category.id}/exams`" class="link">
        <i class="el-icon-finished ic"></i>
        <b class="highlight">{{ category.posts_count }}</b> Exams
      </router-link>
    </p>
    <el-dropdown @command="command">
      <el-button type="primary">
        Thao tác<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="{ type: 'newExam', data: { category } }">
          <i class="el-icon-baseball"></i> Tạp mới
        </el-dropdown-item>
        <el-dropdown-item> <i class="el-icon-edit"></i> Sửa </el-dropdown-item>
        <el-dropdown-item :command="{ type: 'delete', data: { category } }">
          <i class="el-icon-delete"></i> Xoá
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  props: ["category"],
  data() {
    return {
      inviting: {},
      openInvite: false,
    }
  },
  methods: {
    command({ type, data }) {
      if (type === "edit") this.$emit("openEdit", data)
      if (type === "newPost") this.$router.push(`/post/create?categoryId=${this.category.id}`)
      if (type === "newExam") this.$emit("toggleNewExam", data)
      if (type === "delete") this.$emit("openDeleteBox", data)
    },
  },
}
</script>
<style lang="scss" scoped>
.link {
  text-decoration: none;
  color: black;
}

.link:hover {
  text-decoration: none;
  color: #44ce6f;
}

.class {
  &-icon {
    width: 100px;
    height: auto;
  }
  &-box {
    border: none;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.25rem;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 1fr;
    margin-top: 15px;
    align-items: center;
    justify-items: center;
  }

  &-box:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
  }
}

.class-name{
  display: flex;
  align-items: center;
}

.ic {
  font-size: 20px;
  margin-right: 10px;
}
.highlight {
  font-weight: bold;
  color: #44ce6f;
}
</style>