<template>
    <div class="question">
        <div class="title-wrapper">
          <i class="el-icon-check ic-correct-custom" v-if="showAnswers && question.isCorrect"></i>
          <i class="el-icon-close ic-wrong-custom" v-else-if="showAnswers && !question.isCorrect"></i>
          <vue-mathjax :options="options" :formula="question.name" :safe="false"></vue-mathjax>
        </div>
        <div class="answer">
            <div v-for="(choice, index) in question.answers" :key="`answer-${index}`">
                <el-radio v-model="answer" :label="choice.name" 
                  :class="{
                    'is-wrong': showAnswers && choice.name === question.answered && choice.name !== question.correctAnswer,
                    'is-correct': showAnswers && choice.name === question.correctAnswer
                  }"
                  :disabled="showAnswers"
                >
                <vue-mathjax :options="options" :formula="choice.name" :safe="false"></vue-mathjax>
                </el-radio>
            </div>
        </div>
    </div>
</template>

<script>
import { VueMathjax } from "vue-mathjax";

export default {
    components: {
      "vue-mathjax": VueMathjax
    },
    props: ['question', 'showAnswers'],
    data () {
      return {
        answer: this.question.answer,
        options: {
          tex2jax: {
            inlineMath: [['$', '$']],
            displayMath: [['$$', '$$']],
            processEscapes: true,
            processEnvironments: true
          }
        }
      }
    },
    watch: {
			answer () {
			this.$emit('onAnswer', {
					question: this.question,
					answer: this.answer
				})
      },
      question (question) {
        this.answer = question.answer
      }
    }
}
</script>

<style lang="scss" scoped>
.title {
	color: #202124;
	font-size: 16px;
}
.answer{
	display: flex;
	flex-direction: column;
}

.el-radio{
  line-height: 50px;
}

.warning{
  color: #f56c6c;
}

.title-wrapper{
  display: flex;
  align-items: baseline;
}

img {
  max-width: 50% !important;
}

.ic-correct-custom{
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
  color: #67C23A;
}

.ic-wrong-custom{
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
  color: #f56c6c;
}
</style>

<style>
.is-wrong .el-radio__input + .el-radio__label {
  color: #f56c6c !important;
}

.is-wrong .el-radio__input .el-radio__inner{
  border-color: #f56c6c !important;
  background: #f56c6c !important;
}

.is-wrong .el-radio__input .el-radio__inner::after{
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #FFF !important;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%) scale(1);
  transform: translate(-50%,-50%) scale(1);
}

.answer .el-radio__input.is-disabled+span.el-radio__label {
  color: inherit;
}

.is-correct .el-radio__input + .el-radio__label {
  color: #67C23A !important;
}

.is-correct .el-radio__input.is-disabled .el-radio__inner, .el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: #67C23A !important;
  background: #67C23A !important;
}

.is-correct .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #F5F7FA;
}
</style>