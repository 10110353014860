<template>
  <div
    class="icon"
    :class="[`icon--${name}`, `icon--${size}`, { 'has-align-fix': fixAlign }]"
  >
    <svg class="icon__svg">
      <use
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :xlink:href="`#${name}`"
      ></use>
    </svg>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import bold from "../../assets/tiptap/images/icons/bold.svg";
import italic from "../../assets/tiptap/images/icons/italic.svg";
import strike from "../../assets/tiptap/images/icons/strike.svg";
import underline from "../../assets/tiptap/images/icons/underline.svg";
import code from "../../assets/tiptap/images/icons/code.svg";
import paragraph from "../../assets/tiptap/images/icons/paragraph.svg";
import ul from "../../assets/tiptap/images/icons/ul.svg";
import ol from "../../assets/tiptap/images/icons/ol.svg";
import image from "../../assets/tiptap/images/icons/image.svg";

export default {
  props: {
    name: {},
    size: {
      default: "normal",
    },
    modifier: {
      default: null,
    },
    fixAlign: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.3rem;
  top: -0.05rem;
  fill: currentColor;
  // &.has-align-fix {
  // 	top: -.1rem;
  // }
  &__svg {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
// svg sprite
body > svg,
.icon use > svg,
symbol {
  path,
  rect,
  circle,
  g {
    fill: currentColor;
    stroke: none;
  }
  *[d="M0 0h24v24H0z"] {
    display: none;
  }
}
</style>