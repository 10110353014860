<template>
  <div class="container">
    <div class="page">
      <h1 class="congraz" v-if="result.score >= 5">Congratulations!</h1>
      <h1 class="congraz" v-else>You may want to try again.</h1>
      <p class="congraz-desc">With <b class="correct-ans">{{ result.correctAnswers }}</b> correct answers. Your score is</p>
      <div class="score" :class="{'warning': result.score < 5}">
        {{ result.score }}
      </div>
      <div class="action-boxes">
        <el-button type="primary" icon="el-icon-refresh" plain @click="tryAgain">Try again</el-button>
        <el-button type="warning" icon="el-icon-document-checked" plain @click="$emit('showDetails')">Show details</el-button>
        <el-button type="info" icon="el-icon-s-home" plain>Back to home</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('exam', ['result']),
  },
  methods: {
    tryAgain () {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.congraz{
  text-align: center;
  margin-top: 5vh;

  &-desc{
    line-height: 25px;
    font-size: 18px;
    text-align: center;
  }
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606266;
}

.score {
  color: #67C23A;
  font-size: 50px;
  margin-top: 5vh;
  margin-bottom: 10vh;
  text-align: center;
  font-weight: bold;
}

.page {
  width: 70%;
  box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
  background-color: #fff;
  min-height: 95vh;
  margin: 2vh 0 2vh 0;
  padding: 0 2vw 0 2vw;
}

.correct-ans{
  color: #67C23A;
  font-weight: bold;
}

.action-boxes{
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.warning{
  color: #f56c6c;
}
</style>