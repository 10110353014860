<template>
  <div class="question">
    <p
      class="title"
      v-html="question.name"
      :class="{ warning: question.warning }"
    ></p>
    <div class="answer">
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 10 }"
        placeholder="Please enter your answer..."
        v-model="answer"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question"],
  data() {
    return {
      answer: this.question.answer,
    };
  },
  watch: {
    answer() {
      this.$emit("onAnswer", {
        question: this.question,
        answer: this.answer,
      });
    },
    "question.answered"(v) {
      this.answer = v;
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  margin-bottom: 20px;
  width: 100%;
}
.title {
  color: #202124;
  font-size: 16px;
}
.answer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.el-radio {
  line-height: 50px;
}

.warning {
  color: #f56c6c;
}
</style>