<template>
  <div class="selector">
    <div class="selector-top">
      <p>Vui lòng chọn loại câu hỏi</p>
      <i class="el-icon-close"></i>
    </div>
    <ul class="selector-list">
      <li
        class="selector-list-item"
        v-for="type in types"
        :key="type.name"
        @click="selectQuestion(type.name)"
      >
        {{ type.note }}
      </li>
    </ul>
  </div>
</template>

<script>
import types from "@/utils/types";

export default {
  data() {
    return {
      types: Object.values(types),
    };
  },
  methods: {
    selectQuestion(type) {
      this.$store.commit("builder/CHANGE_BUILDER_OPENING", type);
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  max-width: 20em;
  margin-left: 1em;
  box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
  background-color: #fff;
  border-radius: 3px;
  font-size: 15px;

  &-top {
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: thin solid rgba(77, 52, 70, 0.1);
  }

  &-list {
    &-item {
      padding: 18px;
      align-items: center;
      transition: all 0.3s;
      border-bottom: thin solid rgba(77, 52, 70, 0.05);
      border-color: rgba(77, 52, 70, 0.05);
      cursor: pointer;
    }

    &-item:hover {
      text-decoration: none;
      background-color: #f5f5f5;
      border-bottom-color: transparent;
    }
  }
}
</style>