import Vue from 'vue'
import Router from 'vue-router'
import Builder from  '@/views/Builder'
import PdfBuilder from  '@/views/PdfBuilder'
import Login from  '@/views/Login'
import Home from  '@/views/Home'
import Profile from  '@/views/Profile'
import Exam from  '@/views/Exam'
import NewPost from  '@/views/NewPost'
import Posts from  '@/views/Posts'
import EditPost from  '@/views/EditPost'
import Exams from  '@/views/Exams'

Vue.use(Router)

const routes = [
  { path: '/', component: Home, name: 'Home' },
  { path: '/login', component: Login, name: 'Login' },
  { path: '/builder/:id', component: Builder, name: 'Builder' },
  { path: '/pdf-builder/:id', component: PdfBuilder, name: 'PdfBuilder' },
  { path: '/profile', component: Profile, name: 'Profile' },
  { path: '/exam/:id', component: Exam, name: 'Exam' },
  { path: '/post/create', component: NewPost, name: 'NewPost' },
  { path: '/post/edit/:slug', component: EditPost, name: 'EditPost' },
  { path: '/category/:id/posts', component: Posts, name: 'Posts' },
  { path: '/category/:id/exams', component: Exams, name: 'Exams' },
]

export function createRouter () {
  return new Router({
    mode: 'history',
    routes
  })
}