<template>
  <div>
    <section class="question">
      <div class="question-wrapper">
        <Tiptap @onChangeText="onChangeText" />
      </div>
      <el-input placeholder="Câu hỏi nhập text" :disabled="true"></el-input>
    </section>
    <AdvanceSetting />
    <div class="action-group">
      <el-button
        v-if="workspace.validate"
        type="primary"
        size="small"
        @click="save"
      >
      Hoành thành <i class="el-icon-circle-check"></i>
      </el-button>
      <el-button v-else type="primary" size="small" disabled>
        Hoành thành <i class="el-icon-circle-check"></i>
      </el-button>
      <el-button type="info" size="small" @click="cancel">
        Huỷ <i class="el-icon-close"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AdvanceSetting from "@/components/builder/AdvanceSetting.vue";
import Tiptap from "../Tiptap.vue";

export default {
  components: { AdvanceSetting, Tiptap },
  data() {
    return {
      name: null,
      validated: false,
    };
  },
  computed: {
    ...mapState("builder", ["editing", "workspace"]),
  },
  methods: {
    save() {
      this.$store.dispatch("builder/addQuestion", this.editing.question);
    },
    cancel() {
      this.$store.commit("builder/RESET_BUILDER");
    },
    onChangeText(name) {
      this.name = name;
    },
  },
  watch: {
    name: function () {
      if (this.name) {
        return this.$store.commit(
          "builder/UPDATE_EDITING_QUESTION_NAME",
          this.name
        );
      }

      return this.$store.commit("builder/UPDATE_VALIDATE", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  &-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 10px;
  }

  &-name {
    width: 100%;
    border-top: none;
    border-bottom: 1px dashed;
    border-left: none;
    border-right: none;
    outline-width: 0;
    height: 46px;
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.action-group {
  margin-top: 20vh;
}

.warning::placeholder {
  color: #f56c6c;
}

.warning {
  caret-color: #f56c6c;
  border-bottom-color: #f56c6c;
}
</style>