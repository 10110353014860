<template>
	<div>
		<router-view></router-view>
		<vue-progress-bar></vue-progress-bar>
	</div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">

/* Reset css from https://meyerweb.com/eric/tools/css/reset/ */

html, body, div, span, applet, object, iframe,
 h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, 
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}


blockquote {
	letter-spacing: 0;
	font: normal 400 16px/24px AkkuratPro,Arial,sans-serif;
	box-sizing: border-box;
	width: 100%;
	margin: 32px 0;
	padding: 24px 32px;
	border-radius: 10px;
	background-color: #f1f2fa;
	color: #3b3e4d;
	line-height: 28px;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body{
	font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
	font-weight: 400;
}

.ProseMirror{
  outline: none !important;
}

</style>