import axios from 'axios'
import app from '@/main'

const instance = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}/api/v1`
})

instance.interceptors.request.use(config => {
    app.$Progress.start()
    const token = app.$cookies.get('token')
    config.headers.Authorization = token

    return config
})

instance.interceptors.response.use(res => response(res), e =>  error(e))

const response = (response) => {
    app.$Progress.finish()

    return response
}

const error = (error) => {
    app.$Progress.finish()
    if(error.response.status === 401) app.$router.push('/login')

    return Promise.reject(error)
}

export default instance