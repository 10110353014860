import {
  getExamApi, updateExamNameApi, updatePageNameApi,
  addPageApi, removePageApi, updateQuestionNameApi,
  removeQuestionApi, addQuestionApi, editPageApi,
  editQuestionApi, copyQuestionApi, orderQuestionApi,
  updateSettingsApi, configAnswers, updateAvatar, generatePageQuestionsApi
} from '@/api/builder.js'
import { message } from '@/utils/message.js'
import { validateQuestion } from '@/utils/validateQuestion.js'

const state = {
  exam: {},
  editing: {
    question: {
      answers: []
    },
    page: {}
  },
  workspace: {
    loading: false,
    validate: false,
    opening: 'DEFAULT',
    openPreview: false
  }
}

// getters
const getters = {
  editingPage: state => {
    return state.editing.page
  },
  editingQuestion: state => {
    return state.editing.question
  }
}

// actions
const actions = {
  async generatePageQuestions({commit}, requestData) {
    commit('SET_LOADING', true)
    try {
      const response = await generatePageQuestionsApi(requestData)
      commit('ADD_PAGE', response)
    } catch ({response}) {
      if (response.status !== 401) message('Unable to load exam data.', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async getExam({commit}, id) {
    commit('SET_LOADING', true)
    try {
      const response = await getExamApi(id)
      commit('SET_EXAM', response.data)
    } catch ({response}) {
      if (response.status !== 401) message('Unable to load exam data.', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updateExamName({commit}, data) {
    commit('SET_LOADING', true)
    try {
      await updateExamNameApi(data)
      commit('SET_EXAM_NAME', data.name)
      message('Update name successfully.', 'success')
    } catch (e) {
      message('Oops, can not update name.', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updatePageName({commit}, data) {
    commit('SET_LOADING', true)
    try {
      await updatePageNameApi(data)
      commit('SET_PAGE_NAME', data)
      message('Update page name successfully.', 'success')
    } catch (e) {
      message('Oops, can not update page name !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async adPage({commit}, data) {
    commit('SET_LOADING', true)
    try {
      const reponse = await addPageApi(data)
      commit('ADD_PAGE', reponse)
      message('Add page successfully', 'success')
    } catch (e) {
      message('Oops, can not add page !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async deletePage({commit}, id) {
    commit('SET_LOADING', true)
    try {
      await removePageApi(id)
      commit('REMOVE_PAGE', id)
      message('Delete page successfully', 'success')
    } catch (e) {
      message('Oops, can not delete this page !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updateQuestionName({commit}, data) {
    commit('SET_LOADING', true)
    try {
      const response = await updateQuestionNameApi(data)
      commit('UPDATE_PAGE', response)
      message('Update question name successfully.', 'success')
    } catch (e) {
      message('Oops, can not update this question !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async deleteQuestion({commit}, id) {
    commit('SET_LOADING', true)
    try {
      const response = await removeQuestionApi(id)
      commit('UPDATE_PAGE', response)
      message('Delete question successfully.', 'success')
    } catch (e) {
      message('Oops, can not delete this question !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async addQuestion({commit}, requestData) {
    commit('SET_LOADING', true)
    try {
      const {data} = await addQuestionApi(requestData)
      commit('UPDATE_PAGE', {data: data.page})
      commit('START_EDIT_QUESTION', { type: `EDIT_${data.question.type}`, question: data.question })
    
      message('Add question successfully.', 'success')
    } catch (e) {
      message('Oops, can not add question !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async editPage({commit}, { data, id }) {
    commit('SET_LOADING', true)
    try {
      await editPageApi(data, id)
      commit('EDIT_PAGE', { data, id })
      message('Update page successfully', 'success')
    } catch (e) {
      message('Oops, can not add question !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  addAnswer({commit}) {
    commit('ADD_ANSWER')
  },
  
  addLevel({commit}, level) {
    commit('ADD_LEVEL', level)
  },

  addCategory({commit}, id) {
    commit('ADD_CATEGORY', id)
  },

  updateLevel({commit}, data) {
    commit('UPDATE_LEVEL', data)
  },

  removeAnswer({commit}, index) {
    commit('REMOVE_ANSWER', index)
  },

  updateAnswer({commit}, data) {
    commit('UPDATE_ANSWER', data)
  },

  quickAdd({commit}, { answers, type }) {
    switch (type) {
      case 'ADDITIONAL_ADD':
        return commit('ADDITIONAL_ADD', answers)
      case 'RESET_ALL_ADD':
        return commit('RESET_ALL_ADD', answers)
      case 'REPLACE_ADD':
        return commit('REPLACE_ADD', answers)
    }
  },

  async editQuestion({commit}, data) {
    commit('SET_LOADING', true)
    try {
      const response = await editQuestionApi(data)
      commit('EDIT_QUESTION', response)
      message('Update question successfully.', 'success')
    } catch (e) {
      message('Oops, can not edit question !', 'error')
    }
    finally {
      commit('SET_LOADING', false)
    }
  },
  async copyQuestion({commit}, id) {
    commit('SET_LOADING', true)
    try {
      const response = await copyQuestionApi(id)
      commit('EDIT_QUESTION', response)
      message('Copy question successfully.', 'success')
    } catch (e) {
      message('Oops, can not add question !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async orderQuestion({commit}, {list, id}) {
    commit('SET_LOADING', true)
    try {
      const response = await orderQuestionApi(list, id)
      commit('EDIT_QUESTION', response)
      message('Reorder question successfully.', 'success')
    } catch (e) {
      message('Oops, unable to reorder question !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },
  previewQuestion({commit}, question) {
    commit('PREVIEW_QUESTION', question)
  },

  async updateSettings({commit}, {id, data}) {
    commit('SET_LOADING', true)
    try {
      await updateSettingsApi(id, data)
      message('Update exam settings successfully.', 'success')
    } catch (e) {
      message('Oops, unable to update exam settings', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updateAvatar({commit}, {id, avatar}) {
    commit('SET_LOADING', true)
    try {
      await updateAvatar(id, avatar)
      message('Update exam avatar successfully.', 'success')
    } catch (e) {
      message('Oops, unable to update exam avatar', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async configAnswers({commit}, {id, answers}) {
    commit('SET_LOADING', true)
    try {
      const {data} = await configAnswers(id, answers)
      commit('SET_EXAM', data)
      message('Update exam settings successfully.', 'success')
    } catch (e) {
      console.log(e)
      message('Oops, unable to update exam settings', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

// mutations

const mutations = {
  SET_EXAM(state, exam) {
    state.exam = exam
  },

  SET_LOADING(state, loading) {
    if (loading) {
      state.workspace.loading = loading
    } else {
      state.workspace.loading = !state.workspace.loading
    }
  },

  SET_EXAM_NAME(state, name) {
    state.exam.name = name
  },

  SET_PAGE_NAME(state, { id, name }) {
    const index = state.exam.pages.findIndex(x => x.id == id)
    state.exam.pages[index].name = name
  },

  ADD_PAGE(state, response) {
    state.exam.pages.push(response.data)
  },

  REMOVE_PAGE(state, id) {
    const index = state.exam.pages.findIndex(x => x.id == id)
    state.exam.pages.splice(index, 1)
    state.editing.page = {}
    state.workspace.opening = 'DEFAULT'
  },

  UPDATE_PAGE(state, {data}) {
    const index = state.exam.pages.findIndex(x => x.id == data.id)
    state.exam.pages[index] = data
  },

  CHANGE_BUILDER_OPENING(state, type) {
    state.workspace.opening = type
    // Need a better logic
    if (['TEXT', 'SINGLE_ANSWER', 'DROP_DOWN', 'MULTIPLE_CHOISE'].includes(type))
      state.editing.question.type = type
  },

  UPDATE_EDITING_QUESTION_NAME(state, name) {
    state.editing.question.name = name
    state.workspace.validate = validateQuestion(state.editing.question)
  },

  QUESTION_SELECTED(state, { pageId, type }) {
    state.editing.question = {
      answers: [],
      pageId: pageId
    }

    state.workspace.opening = type
  },

  ADD_ANSWER(state) {
    if (state.editing.question.answers.length === 0) {
      state.editing.question.answers.push({ name: '', correct: true, note: '', validate: false })
    } else {
      state.editing.question.answers.push({ name: '', correct: false, note: '', validate: false })
    }

    state.workspace.validate = validateQuestion(state.editing.question)
  },

  ADD_LEVEL(state, level) {
    state.editing.question.level = level
  },

  ADD_CATEGORY(state, id) {
    state.editing.question.category_id = id
  },

  REMOVE_ANSWER(state, index) {
    state.editing.question.answers.splice(index, 1)
    const curentCorrect = state.editing.question.answers.findIndex(ans => ans.correct)
    if (curentCorrect < 0 && state.editing.question.answers.length > 0)
      state.editing.question.answers[0].correct = true
    state.workspace.validate = validateQuestion(state.editing.question)
  },

  UPDATE_ANSWER(state, { index, name }) {
    state.editing.question.answers[index].name = name
    if (name === '') {
      state.editing.question.answers[index].validate = false
    } else {
      state.editing.question.answers[index].validate = true
    }
    state.workspace.validate = validateQuestion(state.editing.question)
  },

  UPDATE_VALIDATE(state, status) {
    state.workspace.validate = status
  },

  RESET_BUILDER(state) {
    state.editing = {
      question: {
        answers: []
      },
      page: {}
    }
    state.workspace = {
      loading: false,
      validate: false,
      opening: 'DEFAULT'
    }
  },

  MARK_CORRECT(state, index) {
    const { question } = state.editing
    if (question.type !== 'MULTIPLE_CHOISE') {
      const curentCorrect = question.answers.findIndex(ans => ans.correct)
      if (curentCorrect > -1) state.editing.question.answers[curentCorrect].correct = false
      state.editing.question.answers[index].correct = true
    } else {
      if (state.editing.question.answers[index].correct) state.editing.question.answers[index].correct = false
      else state.editing.question.answers[index].correct = true
    }

    return state.workspace.validate = validateQuestion(state.editing.question)
  },
  ADDITIONAL_ADD(state, text) {
    text.map(item => state.editing.question.answers.push({
      name: item,
      validate: true,
      correct: 0,
      note: ''
    }))
  },

  RESET_ALL_ADD(state, text) {
    state.editing.question.answers = []
    text.map((item, index) => state.editing.question.answers.push({
      name: item,
      validate: true,
      correct: index === 0,
      note: ''
    }))
  },

  REPLACE_ADD(state, text) {
    state.editing.question.answers.splice(0, text.length)
    text.map((item, index) => state.editing.question.answers.push({
      name: item,
      validate: true,
      correct: index === 0,
      note: ''
    }))
  },

  START_EDIT_PAGE(state, page) {
    state.workspace.opening = 'EDIT_PAGE'
    state.editing.page = page
  },

  EDIT_PAGE(state, { data, id }) {
    const index = state.exam.pages.findIndex(x => x.id == id)
    state.exam.pages[index].name = data.name
    state.exam.pages[index].note = data.note
  },

  START_EDIT_QUESTION(state, { type, question }) {
    state.workspace.opening = type
    state.editing.question = question
  },

  EDIT_QUESTION(state, {data}) {
    const index = state.exam.pages.findIndex(x => x.id == data.id)
    state.exam.pages[index] = data
  },

  PREVIEW_QUESTION(state, question) {
    state.workspace.openPreview = true
    state.workspace.preview = question 
  },

  RESET_PREVIEW() {
    state.workspace.openPreview = false
    state.workspace.preview = {} 
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}