<template>
  <section class="setting">
    <div class="setting-top">
      <div class="setting-group">
        <el-tooltip
          class="item"
          effect="dark"
          content="Câu hỏi của bạn trên thực tế sẽ hiển thị như dưới đây"
          placement="bottom"
        >
        <i class="el-icon-view"></i>
        </el-tooltip>
      </div>
      <div class="require-group">
      </div>
    </div>
    <div class="setting-main">
      <Single
        v-if="editing.question.type === 'SINGLE_ANSWER'"
        :question="editing.question"
      />
      <TextQuestion
        v-if="editing.question.type === 'TEXT'"
        :question="editing.question"
      />
      <Select
        v-if="editing.question.type === 'DROP_DOWN'"
        :question="editing.question"
      />
      <!-- <Multiple v-if="editing.question.type === 'MULTIPLE_CHOISE'" :question="editing.question"/> -->
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Single from "../questions/Single";
import TextQuestion from "../questions/TextQuestion";
import Select from "../questions/Select";
/* import Multiple from '@/components/questions/Multiple' */

export default {
  components: { Single, TextQuestion, Select /* Multiple */ },
  computed: {
    ...mapState("builder", ["editing"]),
  },
};
</script>

<style lang="scss" scoped>
.dev-mode {
  font-weight: 100;
}
.setting {
  width: 100%;
  overflow: visible;
  margin: 2em 0 0 0;
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  border: thin solid #ededed;
  display: flex;
  flex-direction: column;
}

.setting:before {
  content: " ";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  bottom: auto;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #ededed transparent;
  left: 20px;
  top: -31px;
}

.setting:after {
  content: " ";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  bottom: auto;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  left: 20px;
  top: -30px;
}

.setting-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 5px 0 0 !important;
  border-bottom: thin solid rgba(77, 52, 70, 0.1);
}

.setting-group {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 25px;
}

.require-group {
  flex-grow: 9;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.setting-main {
  padding: 5px 0 5px 0;
  min-height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px;
}

.logo {
  width: 50px;
  height: auto;
}
</style>