<template>
    <div>
        <el-button type="text" @click="dialogVisible = true"></el-button>
        <el-dialog
            title="Delete category"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
        >
            <slot></slot>
        <span slot="footer" class="dialog-footer">
            <el-button @click="$emit('closeDeleteBox')">Cancel</el-button>
            <el-button type="danger" @click="$emit('delete', payload)">Confirm</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['open', 'payload'],
    data(){
        return {
            dialogVisible: false
        }
    },
    methods: {
        handleClose() {
            this.$emit('closeDeleteBox')
        }
    },
    watch: {
        open(v){
            this.dialogVisible = v
        }
    }
}
</script>