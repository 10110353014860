<template>
  <div class="class-box">
    <div class="first-col">
      <img src="@/assets/exam.png" class="class-icon" />
    </div>
    <div class="class-info-item second-col">
      <p class="class-name">{{ post.title }}</p>
      <p class="last-edit">
        <b>Last update:</b> {{ post.updated_at | updatedAt }}
      </p>
    </div>
    <p class="class-info-item">
      <router-link :to="`/builder/${post.id}`" class="link">
        <i class="el-icon-star-off ic"></i>
        <b class="highlight">{{ post.exams_count }}</b> Đề thi
      </router-link>
    </p>
    <el-dropdown @command="command" class="custom-dropdown">
      <el-button type="primary">
        Thao tác<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <router-link :to="`/post/edit/${post.slug}`" class="custom-link-item">
          <el-dropdown-item :command="{ type: 'edit', data: { post } }">
              <i class="el-icon-edit"></i> Sửa
          </el-dropdown-item>
        </router-link>
        <el-dropdown-item :command="{ type: 'setting', data: { post } }"
          ><i class="el-icon-setting"></i> Cài đặt</el-dropdown-item
        >
        <el-dropdown-item :command="{ type: 'deactive', data: { post } }"
          ><i class="el-icon-check"></i> Tạm ẩn</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import moment from "moment"

export default {
  props: ["post"],
  methods: {
    command({ type, data }) {
      if (type === "share") this.$emit("shareLink", data)
      if (type === "delete") this.$emit("openDeleteBox", data)
    },
  },
  filters: {
    updatedAt(time) {
      return moment(time).fromNow()
    },
  },
}
</script>
<style lang="scss" scoped>
.last-edit {
  font-size: 15px;
  color: #63686f;
}
.first-col {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.second-col {
  padding-left: 5vw;
}
.link {
  text-decoration: none;
  color: black;
}
.class-info-item {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}
.class-name {
  font-weight: bold;
}
.link:hover {
  text-decoration: none;
  color: #44ce6f;
}
.custom-dropdown {
  height: 40px;
}
.class {
  &-icon {
    width: 100px;
    height: auto;
  }
  &-box {
    border: none;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.25rem;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 2fr 4fr 2fr 2fr;
    margin-top: 15px;
    align-items: inherit;
    justify-items: center;
  }

  &-box:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
  }
}

.ic {
  font-size: 20px;
  margin-right: 10px;
}
.highlight {
  font-weight: bold;
  color: #44ce6f;
}

.custom-link-item {
  text-decoration: none;
  color: #63686f;
}
</style>