<template>
  <section>
    <div>
      <Header />
      <section class="main">
        <Menu />
        <div class="workspace" element-loading-background="rgba(0, 0, 0, 0.8)">
          <div class="form">
            <div class="avatar">
              <el-image
                style="width: 100px; height: 100px"
                :src="renderAvatar()"
                :fit="'fill'"
              >
              </el-image>
              <el-upload
                class="upload-avatar"
                :action="`${domain}/api/v1/storage/image/upload`"
                :on-success="afterUploadAvatar"
                :headers="headers"
                :multiple="false"
                :file-list="fileList"
              >
                <el-button size="mini" class="custom-btn" icon="el-icon-upload"
                  >upload</el-button
                >
              </el-upload>
            </div>
            <div class="form-item">
              <p class="label">Fullname</p>
              <el-input v-model="name" clearable> </el-input>
              <p
                class="error"
                v-for="(item, index) in validateProfile.name"
                :key="`password-${index}`"
              >
                {{ item }}
              </p>
            </div>
            <div class="form-item">
              <p class="label">Email</p>
              <el-input v-model="email" disabled clearable> </el-input>
              <p
                class="error"
                v-for="(item, index) in validateProfile.email"
                :key="`password-${index}`"
              >
                {{ item }}
              </p>
            </div>
            <div class="form-item">
              <p class="label">Old password</p>
              <el-input v-model="oldPassword" show-password clearable>
              </el-input>
              <p
                class="error"
                v-for="(item, index) in validateProfile.oldPassword"
                :key="`password-${index}`"
              >
                {{ item }}
              </p>
            </div>
            <div class="form-password">
              <div class="form-item">
                <p class="label">New Password</p>
                <el-input v-model="password" show-password clearable>
                </el-input>
                <p
                  class="error"
                  v-for="(item, index) in validateProfile.password"
                  :key="`password-${index}`"
                >
                  {{ item }}
                </p>
              </div>

              <div class="form-item">
                <p class="label">Repeat new password</p>
                <el-input v-model="repass" show-password clearable> </el-input>
                <p
                  class="error"
                  v-for="(item, index) in validateProfile.password"
                  :key="`password-${index}`"
                >
                  {{ item }}
                </p>
              </div>
            </div>
            <el-button type="primary" class="save" @click="save"
              >Update</el-button
            >
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Menu from "@/components/commons/Menu.vue";
import Header from "@/components/commons/Header.vue";
const domain = process.env.VUE_APP_API_URL

export default {
  components: {
    Menu,
    Header,
  },
  computed: {
    ...mapState("auth", ["user", "loading", "validateProfile"]),
  },
  mounted() {
    this.name = this.user.name;
    this.email = this.user.email;
    this.$store.commit("auth/UPDATE_PROFILE_VALIDATE_ERRORS", []);
  },
  data() {
    return {
      domain: domain,
      name: null,
      oldPassword: null,
      password: null,
      repass: null,
      email: null,
      avatar: null,
      avatarUrl: null,
      headers: {
        Authorization: this.$cookies.get("token"),
        Accept: "application/json, text/plain, */*",
      },
      fileList: [],
    };
  },
  methods: {
    afterUploadAvatar(response, file) {
      this.avatar = response.name;
      this.avatarUrl = response.url;
      this.fileList = [{ name: file.name, url: response.url }];
    },
    renderAvatar() {
      return this.avatarUrl ? this.avatarUrl : this.user.avatar;
    },

    save() {
      let user = {
        name: this.name,
        email: this.email,
      };

      if (this.avatar) user.avatar = this.avatar;
      if (this.oldPassword) user.oldPassword = this.oldPassword;
      if (this.password) user.password = this.password;
      if (this.repass) user.password_confirmation = this.repass;

      this.$store.dispatch("auth/updateUserInfo", {
        user,
        userId: this.user.id,
      });
    },
  },
};
</script>

<style scoped>
.main {
  min-height: 95vh;
  display: grid;
  grid-template-columns: 1fr 20fr;
}
.form {
  padding: 2%;
  width: 50%;
}
.label {
  font-size: 13px;
  padding: 9px 0px;
  color: rgb(0 0 0);
}
.form-password {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}
.save {
  margin-top: 15px;
}
.custom-btn {
  width: 100px;
}
</style>