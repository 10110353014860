<template>
  <div v-if="!done || showAnswers">
    <div class="container" v-if="exam.pages">
      <div class="page">
        <section class="page-header">
          <h2 class="page-title">{{ exam.name }}</h2>
          <!-- <CountDown :time="time.left" @endTime="oneTimeEnd" v-if="time"/> -->
        </section>
        <section>
          <p class="page-description" v-html="exam.pages[currentPage].note"></p>
          <div class="questions">
            <div
              v-for="(question, index) in exam.pages[currentPage].questions"
              :key="`question-${index}`"
              class="question"
            >
              <Single
                v-if="question.type === 'SINGLE_ANSWER'"
                :question="question"
                @onAnswer="onAnswer"
                :showAnswers="showAnswers"
              />
              <TextQuestion
                v-if="question.type === 'TEXT'"
                :question="question"
                @onAnswer="onAnswer"
              />
              <Select
                v-if="question.type === 'DROP_DOWN'"
                :question="question"
                @onAnswer="onAnswer"
                :showAnswers="showAnswers"
              />
              <!-- <Multiple v-if="question.type === 'MULTIPLE_CHOISE'" 
                :question="question"
                @onAnswer="onAnswer"
                :showAnswers="showAnswers"
              /> -->
            </div>
          </div>
        </section>
        <ExamFooter
          :pages="exam.pages"
          :currentPage.sync="currentPage"
          :showAnswers="showAnswers"
          @next="next"
          @back="back"
        />
      </div>
    </div>
    <div
      v-else
      v-loading="true"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
  </div>
  <Result @showDetails="showDetails" v-else />
</template>

<script>
import { mapState } from "vuex";
import Single from "@/components/questions/Single";
import TextQuestion from "@/components/questions/TextQuestion";
import Select from "@/components/questions/Select";
/* import Multiple from '@/components/questions/Multiple' */
import ExamFooter from "@/components/commons/ExamFooter";
import Result from "@/components/exam/Result";
/* import CountDown from '@/components/exam/CountDown' */

export default {
  components: {
    TextQuestion,
    Single,
    Select,
    /* Multiple, */
    ExamFooter,
    Result,
    /* CountDown */
  },
  computed: {
    ...mapState("auth", ["user", "loading"]),
    ...mapState("exam", ["exam", "done", "showAnswers", "time"]),
  },
  mounted() {
    if (!this.user.email) {
      this.$store.commit(
        "auth/UPDATE_NEXT_STEP",
        `${window.location.pathname}${window.location.search}`
      );
      this.$router.push({ name: "Login" });
    } else this.$store.dispatch("exam/getExam", this.$route.params.id);
  },
  data() {
    return {
      currentPage: 0,
      countDown: "60:00s",
    };
  },
  methods: {
    next() {
      this.currentPage = this.currentPage + 1
    },
    back() {
      this.currentPage = this.currentPage - 1
    },
    onAnswer({ question, answer }) {
      this.$store.commit("exam/SET_ANSWER", {
        question,
        answer,
        pIndex: this.currentPage,
      })
    },
    showDetails() {
      this.currentPage = 0;
      this.$store.commit("exam/SET_SHOW_ANSWERS", true)
    },
    oneTimeEnd() {
      this.$store.dispatch("exam/submit")
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606266;
}

.point {
  margin-top: 10%;
  font-size: 30px;
  color: #67c23a;
  text-align: center;
}

.page {
  width: 70%;
  box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
  background-color: #fff;
  min-height: 95vh;
  margin: 2vh 0 2vh 0;
  padding: 0 2vw 0 2vw;

  &-title {
    text-align: center;
    width: 95%;
  }

  &-description {
    font-style: italic;
  }

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .question {
    padding-top: 20px;
  }

  .timer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: black;
    i {
      font-size: 20px;
    }
  }

  .countdown {
    margin-top: 3px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .page {
    width: 100%;
    min-height: 100vh;
    margin: unset;
  }
}
</style>