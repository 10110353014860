<template>
  <section class="footer">
    <div class="footer-btn">
      <el-button type="primary" icon="el-icon-right" plain 
        v-if="pages.length > 1 && this.currentPage < pages.length - 1" 
        @click="$emit('next')"
      >
        Next
      </el-button>
      <el-button type="primary" 
          icon="el-icon-back" 
          plain 
          v-if="this.currentPage > 0" 
          @click="$emit('back')"
        >
        Back
      </el-button>
    </div>
    <el-button
      type="default"
      icon="el-icon-close"
      plain v-if="this.currentPage === pages.length - 1 && showClose"
      @click="$emit('close')"
    >
      Close
    </el-button>
    <el-popconfirm
      confirm-button-text='OK'
      cancel-button-text='No, Thanks'
      icon="el-icon-info"
      icon-color="red"
      title="Are you sure to submit?"
      v-else-if="this.currentPage === pages.length - 1 && !showAnswers"
      @confirm="submit"
    >
      <el-button type="primary"
        slot="reference" 
        icon="el-icon-check"
      >
        Submit
      </el-button>
    </el-popconfirm>
    <el-button
      type="default"
      icon="el-icon-s-home"
      plain v-else-if="this.currentPage === pages.length - 1 && showAnswers"
    >
      Back to home
    </el-button>
  </section>
</template>

<script>
export default {
  props: ['pages', 'currentPage', 'showAnswers', 'showClose'],
  methods: {
    submit () {
      this.$store.dispatch('exam/submit')
    }
  }
}
</script>

<style lang="scss">
.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  &-btn{
    margin-right: 5px;
  }
}
</style>