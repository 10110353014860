<template>
  <div v-if="quickAdd">
    <el-input type="textarea" :rows="5" v-model="quickAddText"></el-input>
    <div class="add-btns">
      <div class="quick-add-options" v-if="quickAdd">
        <el-button @click="addiontinal" type="primary" plain>Thêm vào bên dưới</el-button>
        <el-button @click="replace" type="primary" plain>Bỏ qua đáp án trùng</el-button>
        <el-button @click="resetAll" type="primary" plain>Thay thế tất cả</el-button>
      </div>
      <el-button v-if="quickAdd" @click="$emit('open-quick-add')" type="warning"
        >Đóng</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["quickAdd"],
  data() {
    return {
      quickAddText: "",
    };
  },
  methods: {
    addiontinal() {
      const answers = this.quickAddText.match(/[^\r\n]+/g);
      this.$store.dispatch("builder/quickAdd", {
        answers: answers,
        type: "ADDITIONAL_ADD",
      });
    },
    resetAll() {
      const answers = this.quickAddText.match(/[^\r\n]+/g);
      this.$store.dispatch("builder/quickAdd", {
        answers: answers,
        type: "RESET_ALL_ADD",
      });
    },
    replace() {
      const answers = this.quickAddText.match(/[^\r\n]+/g);
      this.$store.dispatch("builder/quickAdd", {
        answers: answers,
        type: "REPLACE_ADD",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.add-btns {
  display: grid;
  grid-template-columns: 10fr 2fr;
  grid-gap: 10px;
  margin-top: 1em;
}

.quick-add-options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
</style>