import app from '@/main'
import { 
  login, updateUserInfo
} from '@/api/auth.js'
import { message } from '@/utils/message.js'

const state = {
  loading: true,
  user: {},
  validate: {},
  validateSignUp: {},
  validateProfile: {},
  nextStep: '/'
}

// getters
const getters = {}

// actions
const actions = {
  async login({commit, state}, req) {
    commit('UPDATE_LOADING', true)
    try {
      const {data} = await login(req)
      commit('UPDATE_USER', data.user)
      app.$cookies.set('token', `Bearer ${data.token}`)
      commit('UPDATE_VALIDATE_ERRORS', {})
      app.$router.push(state.nextStep)
    } catch ({response}) {
      commit('UPDATE_VALIDATE_ERRORS', response.data.errors)
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },

  logout() {
    app.$cookies.remove('token')
    app.$router.push('/login')
  },

  async updateUserInfo ({commit}, {user, userId}) {
    commit('UPDATE_LOADING', true)
    try{
      const {data} = await updateUserInfo(user, userId)
      commit('UPDATE_USER', data.user)
      message('Update profile successfully', 'success')
    } catch ({response}) {
      commit('UPDATE_PROFILE_VALIDATE_ERRORS', response.data.errors)
      message('Unable to update profile', 'error')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  }
}

// mutations

const mutations = {
  UPDATE_USER(state, user) {
    state.user = user
  },
  UPDATE_LOADING(state, status){
    state.loading = status
  },
  UPDATE_VALIDATE_ERRORS(state, errors){
    state.validate = errors
  },
  UPDATE_VALIDATE_SIGNUP_ERRORS(state, errors){
    state.validateSignUp = errors
  },
  UPDATE_PROFILE_VALIDATE_ERRORS(state, errors){
    state.validateProfile = errors
  },
  UPDATE_NEXT_STEP(state, step){
    state.nextStep = step
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}