<template>
    <el-dialog
      title="Upload image"
      :visible="open"
      width="40%"
      :before-close="close"
    >
        <div class="drag-box">
            <el-upload
                class="upload-box"
                drag
                :action="`${domain}/api/v1/storage/image/upload`"
                :file-list="fileList"
                list-type="picture"
                :on-success="afterUploadSuccess"
                :multiple="false"
                :headers="headers"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 8MB</div>
            </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">Cancel</el-button>
            <el-button type="primary" @click="done">Confirm</el-button>
        </span>
    </el-dialog>
</template>

<script>
const domain = process.env.VUE_APP_API_URL

export default {
    props: ['open'],
    data(){
        return {
            headers: {
                Authorization: this.$cookies.get('token'),
                Accept: 'application/json, text/plain, */*'
            },
            image: null,
            fileList: [],
            domain: domain
        }
    },
    methods: {
        afterUploadSuccess(response, file) {
            this.fileList =  [{name: file.name, url: response.url}]
            this.image = {name: file.name, url: response.url}
        },
        done() {
            this.$emit('uploadSuccess', this.image)
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
.upload-box .el-upload{
    width: 100% !important;
}

.upload-box .el-upload-dragger{
    width: 100% !important;
}
</style>