<template>
  <div class="add-page-btn" @click="openAddPAge">
    <el-button type="primary">
      <i class="el-icon-plus"></i>
    </el-button>
    <span class="add-page-text">Thêm trang</span>
  </div>
</template>

<script>
export default {
  methods: {
    openAddPAge() {
      this.$store.commit("builder/CHANGE_BUILDER_OPENING", "ADD_PAGE");
    },
  },
};
</script>

<style lang="scss" scoped>
.add-page {
  &-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    min-height: 42px;
    margin: 1em 0.5em 1em 0;
  }

  &-text {
    font-size: 12px;
    color: #62686f;
    font-weight: 600;
    margin-left: 10px;
  }
}
</style>