import axios from "@/utils/axios"

export const getExamApi = id => axios.get(`/admin/exam/${id}`)
export const updateSettingsApi = (id, data) => axios.patch(`/admin/exam/${id}/settings`, data)
export const configAnswers = (id, answers) => axios.post(`/admin/exam/${id}/config-answers`, {answers})
export const updateAvatar = (id, avatar) => axios.patch(`/admin/exam/${id}/avatar/update`, {avatar})

export const updateExamNameApi = data => axios.patch(`/admin/exam/update-name/${data.id}`, data)
export const updateQuestionNameApi = data => axios.patch(`/admin/question/update-name/${data.id}`, data)
export const removeQuestionApi = id => axios.delete(`/admin/question/${id}`)
export const addQuestionApi = data => axios.post(`/admin/question/create`, data)
export const editQuestionApi = data => axios.patch(`/admin/question/${data.id}`, data)
export const copyQuestionApi = id => axios.post(`/admin/question/copy/${id}`)
export const orderQuestionApi = (data, id) => axios.post(`/admin/page/${id}/order-question`, data)

export const editPageApi = (data, id) => axios.patch(`/admin/page/${id}`, data)
export const addPageApi = data => axios.post(`/admin/page/add/${data.examId}`, data)
export const removePageApi = id => axios.delete(`/admin/page/${id}`)
export const updatePageNameApi = data => axios.patch(`/admin/page/update-name/${data.id}`, data)

export const generatePageQuestionsApi = data => axios.post(`/admin/page/${data.pageId}/generate-questions`, data)
