<template>
  <div>
    <el-dialog
      :visible.sync="openNewExam"
      width="50%"
      title="Tạo đề thi mới"
      :center="true"
      :before-close="handleClose"
    >
      <div class="create-exam-box">

        <div class="form-item">
          <el-radio-group v-model="type">
            <el-radio label="html"></el-radio>
            <el-radio label="pdf"></el-radio>
          </el-radio-group>
        </div>

        <div class="form-item">
          <p class="form-label">Category</p>
          <el-select
            v-model="categoryId"
            :value="categoryId"
            placeholder="Select"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <p class="form-label">Name</p>
          <el-input placeholder="Name" v-model="name"></el-input>
        </div>

        <div class="form-item">
          <p class="form-label">Description</p>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Enter some description or note"
            v-model="description"
          >
          </el-input>
        </div>
        <div class="form-item">
          <p class="form-label">Avatar</p>
          <el-upload
            ref="uploadCover"
            :multiple="false"
            :file-list="fileList"
            :action="`${domain}/api/v1/storage/image/upload`"
            class="upload-demo"
            :on-success="afterUploadSuccess"
            :headers="headers"
            @clearFiles="fileList"
          >
            <el-button size="small" type="default">Click to upload</el-button>
          </el-upload>
        </div>
        <div class="form-btn">
          <el-button type="info">Cancel</el-button>
          <el-button type="primary" @click="create">Done</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
const domain = process.env.VUE_APP_API_URL;

export default {
  props: ["openNewExam"],
  computed: {
    ...mapState("category", ["options"]),
    ...mapState("post", ["searchResults", "loading"]),
  },
  data() {
    return {
      categoryId: null,
      name: null,
      value: "",
      description: "",
      keyword: "",
      fileList: [],
      avatar: null,
      domain: domain,
      headers: { Authorization: this.$cookies.get("token") },
      type: 'html'
    };
  },
  async mounted() {
    await this.$store.dispatch("category/getCategoryAsOptions");
    const categoryId = this.$route.params.id;
    if (categoryId) this.categoryId = parseInt(categoryId);
  },
  methods: {
    handleClose() {
      this.$emit("toggleNewExam", false);
    },
    search(keyword) {
      setTimeout(() => {
        this.$store.dispatch("post/search", keyword);
      }, 200);
    },
    afterUploadSuccess({ url }) {
      this.avatar = url;
      this.fileList = [{ name: url.split("/").pop(), url }];
    },
    create() {
      this.$store.dispatch("exam/create", {
        name: this.name,
        meta_title: this.name,
        meta_description: this.description,
        avatar: this.avatar,
        categoryId: this.categoryId,
        type: this.type
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &-item {
    display: flex;
    flex-direction: column;
  }

  &-label {
    font-weight: bold;
    padding: 20px 0 20px 0;
  }

  &-btn {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 20px;
  }
}
</style>